<template>
    <div class="border-top"></div>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="title">{{ carousel.slideInfo[carousel.current].title }}</h1>
            </div>
            <div class="col-12 col-md-6">
                <div id="carouselExampleIndicators" class="carousel slide" data-touch="false" data-interval="false">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active" @click.prevent="slide(0)"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1" @click.prevent="slide(1)"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2" @click.prevent="slide(2)"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3" @click.prevent="slide(3)"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src="../../assets/images/construction-grp-water-tanks-small.png" class="d-block w-100" alt="Firm Industries">
                    </div>
                    <div class="carousel-item">
                    <img src="../../assets/images/construction-pvc-water-tank-small.png" class="d-block w-100" alt="Firm Industries">
                    </div>
                    <div class="carousel-item">
                    <img src="../../assets/images/construction-road-barriers-small.png" class="d-block w-100" alt="Firm Industries">
                    </div>
                    <div class="carousel-item">
                    <img src="../../assets/images/construction-traffic-cone-small-1.png" class="d-block w-100" alt="Firm Industries">
                    </div>
                </div>
                <a :class="{ 'no-click' : carousel.sliding }" class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" @click.prevent="slide('-1')">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a :class="{ 'no-click' : carousel.sliding }" class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" @click.prevent="slide('+1')">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                </div>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
                <div>
                    <ul class="img-info">
                        <li :key="index" v-for="(info, index) in carousel.slideInfo[carousel.current].points">
                            <img class="no-d" src="../../assets/images/bullet-blue.png" alt="" v-if="index % 2 == 0">
                            <img class="no-d" src="../../assets/images/bullet-gold.png" alt="" v-else>
                            {{ info }}
                        </li>
                    </ul>
                    <p class="text">{{ carousel.slideInfo[carousel.current].text }}</p>
                </div>
            </div>
            <div class="col-12 text-center">
                <p class="text">
                    All accessories are well-stocked in our manufacturing facility and
                    can be made available as per customer requirements.<br>
                    They are optional and incur additional cost.
                </p>
            </div>
        </div>
    </div>
    <div class="border-bottom"></div>
    <div class="bottom">
        <div class="left">
            <img src="../../assets/images/construction-logo.svg" alt="Firm Industries" class="logo">
            <div class="text">
                <p class="title">Vision</p>
                <p class="subtitle">
                    We strive to be the premier choice for plastic moulding solutions in Rotational,
                    Blow and injection moulding technologies in the GCC market. And will continue
                    to work towards making a difference in the lives and future of our employees
                    and our communities while advancing the success of our customers.
                </p>
            </div>
        </div>
        <a :href="`${publicPath}FI_Product_Catalog_A4-2.pdf`" download="catalog" class="download-pamphlet" target="_blank">
            <span>Download</span>Pamphlet
        </a>
    </div>
</template>

<script>
export default {
    name: 'Construction',
    data() {
        return {
            carousel: {
                current: 0,
                slideInfo: [
                    { points: ['Inner Ladder', 'Support Frames', 'Color Options', 'GRP Panels', 'Hot Pressed Panel', 'Foam Insulation'], text: 'The demand for water tanks is escalating in UAE and to suit the business specific requirements, we provide top-notch water storage products that are durable, UV proteted and environment friendly. Firm Industries water tanks are manufatured in the facility by using a highly advanced roto-moulding machine that produce strong and durable water tanks without any joints. With several different colours available.', title: 'Water Tanks' },
                    { points: ['Strong Ribs', 'Foam Layer', 'External White Layer', 'Smooth Inner Layer' ], text: 'The demand for water tanks is escalating in UAE and to suit the business specific requirements, we provide top-notch water storage products that are durable, UV proteted and environment friendly. Firm Industries water tanks are manufatured in the facility by using a highly advanced roto-moulding machine that produce strong and durable water tanks without any joints. With several different colours available.', title: 'Water Tanks' },
                    { points: ['Portable', 'First grade UV', 'Color Options', 'High Visibility'], text: 'Road barriers are made of first-grade UV protected material and offers high visibility. These easy to assemble barricades create a barrier, controls traffic flow, and help pedestrians and vehicles to keep away from hazardous areas.\nOur road barriers are portable and available in various vibrant shades like orange, yellow, blue, green, red, white etc. Additionally are made of nonfading material.', title: 'Road Barriers' },
                    { points: ['High Visibility', 'Top Quality Plastic'], text: 'Reflective and Non-reflective safety cones manufactured by Polyinds are easily movable and are resistant to fading. The wide body design of cones prevents it from tilting over in high winds. Polyinds traffic cones are available in bright colours like orange, yellow and red.', title: 'Traffic Cones' }
                ],
                sliding: false,
            },
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        slide(to) {
            this.carousel.sliding = true;
            setTimeout(() => {
                this.carousel.sliding = false;
            }, 800);
            if(to === '-1') {
                if(this.carousel.current == 0) this.carousel.current = 3;
                else this.carousel.current--;
            } else if(to === '+1') {
                if(this.carousel.current == 3) this.carousel.current = 0;
                else this.carousel.current++;
            } else {
                this.carousel.current = to;
            }
        }
    },
    mounted() {
        document.body.style.backgroundColor = '#F2F2F3';
    }
}
</script>

<style scoped>
ul.img-info {
    list-style: none;
}

ul.img-info li {
    font-family: Montserrat-Light;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

ul.img-info li img {
    height: 20px;
}

h1.title {
    font-family: Montserrat-Bold;
    color: #4A4B58;
    font-size: 14px;
    margin-bottom: 50px;
}

p.title {
    display: inline-block;
    font-family: Montserrat-Bold;
    color: #4A4B58;
}

p.subtitle {
    color: #4A4B58;
    font-family: Montserrat-Light;
}

div.bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 20px;
}

div.bottom .left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
}

div.text {
    margin-top: 60px;
    display: inline-flex;
    flex-direction: column;
}

.no-d {
    -user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

a.no-click {
    pointer-events: none;
}

@media only screen and (max-width: 991px) {
    div.bottom {
        flex-direction: column;
        align-items: center;
    }

    a.download-pamphlet {
        margin: 10px 0 0 10px !important;
    }
}

@media only screen and (max-width: 747px) {
    div.text {
        margin-top: 35px;
    }
}

@media only screen and (max-width: 625px) {
    div.text {
        margin-top: 10px;
    }
}

a.download-pamphlet {
    justify-items: flex-end;
    justify-self: flex-end;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 10px;
    background-color: #4A4B58;
    padding: 30px 25px;
    text-align: center;
    color: #D0B78A;
    border: 2px solid #D0B78A;
    border-radius: 2px;
}

a.download-pamphlet > span {
    display: block;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
}

.container {
    padding-top: 160px;
}

.border-top {
    height: 21px;
    width: 100%;
    background-color: #D0B78A;
    position: absolute;
    top: 109px;
}

.border-bottom {
    height: 20px;
    width: 100%;
    background-color: #D0B78A;
    margin-top: 50px;
}

img.logo {
    margin-top: -14px;
    width: 110px;
    -user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

figure {
    position: relative;
}

figure > img.tank {
    width: 35%;
}

figure > img.rectangle {
    position: absolute;
    width: 40%;
    top: 19%;
    left: 30%;
    z-index: -1;
    -user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

figure > img.info {
    position: absolute;
    width: 5%;
    -user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

figure > img.info-1 {
    left: 28%;
    top: 27%;
}

figure > img.info-2 {
    left: 29%;
    top: 50%;
}

figure > img.info-3 {
    left: 29%;
    top: 75%;
}

figure > img.info-4 {
    right: 29%;
    top: 27%;
}

figure > img.info-5 {
    right: 28.5%;
    top: 50%;
}

figure > img.info-6 {
    right: 28.5%;
    top: 75%;
}

p.text:first-of-type {
    margin-top: 50px !important;
    margin-bottom: 20px !important;
}

p.text {
    font-family: Montserrat-Light;
    color: #4A4B58;
}

@media only screen and (max-width: 991px) {
    .container {
        padding-top: 140px;
    }

    .border-top {
        top: 79px;
    }
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='0000007a' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='0000007a' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #0000007a;
    background-clip: padding-box;
    border: 1px solid #ffffff15;
    /* border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; */
    opacity: .5;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}
</style>